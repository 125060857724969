// Page
@import url("http://fast.fonts.net/t/1.css?apiType=css&projectid=23d122d0-9ec4-4183-84a1-b94b48d3be95");
@font-face{
  font-family:"AvenirNextLTW01-UltraLi";
  src:url("../assets/fonts/17c55b27-e9ab-43cd-b948-d49f97480d68.eot?#iefix");
  src:url("../assets/fonts/17c55b27-e9ab-43cd-b948-d49f97480d68.eot?#iefix") format("eot"),url("../assets/fonts/2e3f5cb9-101f-46cf-a7b3-dfaa58261e03.woff2") format("woff2"),url("../assets/fonts/fa19948e-5e38-4909-b31e-41acd170d6f2.woff") format("woff"),url("../assets/fonts/6de0ce4d-9278-467b-b96f-c1f5f0a4c375.ttf") format("truetype"),url("../assets/fonts/9fd4ea0c-b19a-4b21-9fdf-37045707dd78.svg#9fd4ea0c-b19a-4b21-9fdf-37045707dd78") format("svg");
}
@font-face{
  font-family:"AvenirNextLTW01-Regular";
  src:url("../assets/fonts/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix");
  src:url("../assets/fonts/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix") format("eot"),url("../assets/fonts/2cd55546-ec00-4af9-aeca-4a3cd186da53.woff2") format("woff2"),url("../assets/fonts/1e9892c0-6927-4412-9874-1b82801ba47a.woff") format("woff"),url("../assets/fonts/46cf1067-688d-4aab-b0f7-bd942af6efd8.ttf") format("truetype"),url("../assets/fonts/52a192b1-bea5-4b48-879f-107f009b666f.svg#52a192b1-bea5-4b48-879f-107f009b666f") format("svg");
}
@font-face{
  font-family:"Avenir Next LT W01 Demi";
  src:url("../assets/fonts/12d643f2-3899-49d5-a85b-ff430f5fad15.eot?#iefix");
  src:url("../assets/fonts/12d643f2-3899-49d5-a85b-ff430f5fad15.eot?#iefix") format("eot"),url("../assets/fonts/aad99a1f-7917-4dd6-bbb5-b07cedbff64f.woff2") format("woff2"),url("../assets/fonts/91b50bbb-9aa1-4d54-9159-ec6f19d14a7c.woff") format("woff"),url("../assets/fonts/a0f4c2f9-8a42-4786-ad00-fce42b57b148.ttf") format("truetype"),url("../assets/fonts/99affa9a-a5e9-4559-bd07-20cf0071852d.svg#99affa9a-a5e9-4559-bd07-20cf0071852d") format("svg");
}
html {
  margin: 0;
  padding: 0;
}
body{
  font-family: 'Avenir Next LT W01 Demi', sans-serif;
  font-size: 16px;
  line-height: 1.375;
  color: $color-text;
  background: $color-white;
}
.home {
  .bg-image {
    //height: 810px;
    height: 72vh;
    width: 100%;
    transition: height 0.5s;
    -webkit-transition: all 0.5s;
    background-attachment: fixed !important;

    &.scroll{
      height: 20vw;
    }
  }
  .c-menu{
    min-height: 28vh;
  }
  .header-mobile{
    display: none;
  }
  .slider{
    //height: 42.19vw;
    height: 72vh;
    @include box-shadow(0, 3px, 6px, rgba(0, 0, 0, 0.5));
  }

}
.lSSlideOuter{
  @include box-shadow(0, 3px, 6px, rgba(0, 0, 0, 0.5));
}

.rel{
  position: relative;
}

.arrow-down{
  width: 15px;
  height: 15px;
  background: url(../assets/images/arrow_down.svg) no-repeat;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: 0 auto;
  &:hover{
    cursor: pointer;
  }
}
.home-col-box{
  padding: 96px 0 70px;
}
.page-col-box{
  padding: 138px 0 10px;
}
.header{
  background: $color-brown;
  overflow: auto;
}
.home_header{
  position: relative;
  z-index: 11;
  width: 100%;
  background: #fff;
}
.home_scroll{
  position: relative;
  z-index: 1;
  width: 100%;
  background: #fff;
  .column{
    padding-right: 20px !important;
    a{
      color: $color-head;
    }
  }
  h2 a{
    text-decoration: none;
  }
}

.sublayout{
  display: none;
  .columns{
    padding: 0 0 70px 0;
  }
  .column{
    padding-right: 20px !important;
  }
}
.sublayout-1{
  display: block;
}
.home-mobile{
  display: none;
  width: 100px;
  padding: 6px 0 6px 13px;
  a{
    font-size: 15px;
    font-family: 'AvenirNextLTW01-Regular';
    font-weight: 500;
    line-height: 1.1333333333333333;
    color: $color-white;
    text-decoration: none;
  }
}
.title-menu{
  display: none;
}
.title{
  display: none;
  font-size: 30px;
  color: $color-text;
  font-family: 'AvenirNextLTW01-UltraLi';
}

.entry-locations{
  display: none;
}

.entry-meta span
{
  color: $color-head;
  font-size: 20px;
  &.time-start{
    color: $color-text;
    font-family: 'AvenirNextLTW01-Regular';
    font-size: 20px;
  }
}
.entry-meta a{
  color: $color-head;
  font-size: 20px;
  &:hover{
    color: $color-text;
  }
}

.entry-meta .p-text{
  color: $color-text;
  font-family: 'AvenirNextLTW01-Regular';
  font-size: 20px;
}
