/*
  Project: Kirche
  Author: Dawid Kotlarz
 */


@import 'bulma/bulma';
@import '../assets/lightslider/css/lightslider.min.css';
@import "settings/_colors.scss";
@import "settings/_global.scss";
@import "tools/_mixins.scss";
@import "generic/_box-sizing.scss";
@import "generic/_normalize.scss";
@import "generic/_reset.scss";
@import "generic/_shared.scss";
@import "objects/_list-bare.scss";
@import "components/_footer.scss";
@import "components/_layout.scss";
@import "trumps/_utilities.scss";
@import "elements/_headings.scss";
@import "elements/_hr.scss";
@import "elements/_links.scss";
@import "elements/_lists.scss";
@import "elements/_page.scss";

@media(max-width: 1500px){
  .c-nav-main li {
    .bg-menu {
      width: 136px;
      height: 136px;
      background-size: contain;
    }
    a {
      font-size: 27px;
      letter-spacing: 0.27px;
      padding: 0 0 15px 15px;
      width: 180px;
    }
  }
  .submenu{
    li{
      a{
        font-size: 30px;
        line-height: 1.3666666666666667;
      }
    }
  }
  .sublayout{
    .column{
      padding-right: 20px !important;
    }
  }
  .home-link, .impressum-link{
    font-size: 12px;
    padding: 12px 1.459vw;
  }
  .page-col-box {
    padding: 96px 0 10px;
  }
  .sublayout .columns {
    padding: 0 0 40px 0;
  }
  .home-col-box {
    padding: 46px 0 70px;
  }
  .home{
    .c-menu{
      min-height: 26vh;
    }
    .bg-image, .slider{
      height: 74vh !important;
    }
  }

}
@media(max-width: 1024px){
  .c-nav-main{
    li{
      a{
        display: block;
        padding: 18px 0 0 0;
        width: 160px;
      }
    }
  }
  .home h2, .home h1{
    font-size: 19px;
    line-height: 1.368421052631579;
  }
  .home p{
    font-size: 12px;
    line-height: 1.3333333333333333;
  }

  .home{
    .c-menu{
      min-height: 44vh;
    }
    .bg-image, .slider{
      height: 56vh !important;
    }
  }

  .page-container {
    padding: 2.409vw;
  }
  .header{
    height: 80px;
    position: relative;
    @include transition;
    &.expand{
      height: 720px;
      .submenu-mobile{
        display: block;
      }
      .impressum-link, .home-link{
        display: block;
        position: absolute;
        bottom: 10px;
        left: 20px;
        font-family: 'AvenirNextLTW01-UltraLi';
        font-size: 18px;
        color: $color-white;
        padding: 10px 0;
      }
      .home-link{
        left: 20px;
      }
      .impressum-link{
        left: 90px;
      }
    }
    .impressum-link, .home-link{
      display: none;
    }

    .home-mobile{
      padding-left: 22px;
      display: block;
    }
  }
  .navbar_btn{
    display: block;
  }
  .submenu-col{
    display: none;
  }
  .c-nav-main{
    display: none;
  }
  .home .c-nav-main{
    display: block;
  }
  .title-menu{
    display: block;
  }
  .title-1 {
      display: block;
  }
  .page-col-box {
    padding: 6px 0 10px;
    .column{
      margin-bottom: 30px !important;
    }
  }

}
@media screen and (min-width: 769px){
  .column.is-offset-2, .column.is-offset-2-tablet {
    margin-left: 16.66667% !important;
  }
}

@media(max-width: 786px){
  .home-col-box {
    padding: 46px 0 150px;
  }

}

@media(max-width: 600px){
  .is-flex-mobile{
    display: block !important;
  }
  .is-8-mobile{
    width: 100% !important;
  }
  .footer{
    position: relative;
  }
  .home-col-box {
    padding: 46px 0 20px;
  }

  .home .bg-image{
    background: $color-brown !important;
    height: 80px;
    .impressum-link{
      display: none;
    }
  }
  .home .slider{
    display: none;
  }
  .home .header-mobile{
    display: block;
  }
  .c-nav-main{
    li{
      margin-bottom: 20px !important;
      display: block;
      .bg-menu{
        width: 140px;
        height: 140px;
      }
      a{
        display: inline-block;
        padding: 0 0 18px 18px;
      }
    }
  }

  .header{
    &.expand{
      height: 430px;
      .submenu-mobile{
        padding: 50px 20px;
      }
    }
  }
  .page-container, .footer .page-container {
    padding: 20px;
  }
  .home{
    .home_scroll{
      .column{
        margin-bottom: 50px;
      }
    }
  }
  .home-mobile{
    display: block;
  }
}
@media(max-width: 370px){
  .c-nav-main{
    li{
      a{
        display: block;
        padding: 15px 0 0 0;
      }
    }
  }
}

