// Mixins

/* Clearfix */
@mixin clearfix {
  &:after {
    clear: both;
    content: '';
    display: block;
  }
}


@mixin transition{
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

@mixin border-radius($v){
  border-radius: $v;
  -moz-border-radius: $v;
  -webkit-border-radius: $v;
}

@mixin box-shadow($a, $b, $c, $color){
  box-shadow: $a $b $c $color;
  -moz-box-shadow: $a $b $c $color;
  -webkit-box-shadow: $a $b $c $color;
  -o-box-shadow: $a $b $c $color;

}

@mixin placeholder($color){
  ::placeholder{
    color: $color;
  }
  ::-webkit-input-placeholder{
    color: $color;
  }
  ::-moz-placeholder{
    color: $color;
  }
}

/* 320px */
@mixin bp-small {
  @media (min-width: $bp-small) {
    @content;
  }
}

/* 768px */
@mixin bp-medium {
  @media (min-width: $bp-medium) {
    @content;
  }
}

/* 1024px */
@mixin bp-large {
  @media (min-width: $bp-large) {
    @content;
  }
}

/* 1600px */
@mixin bp-full {
  @media (min-width: $bp-full) {
    @content;
  }
}
