// Links
a {
  color: $color-text;
  text-decoration: underline;
  @include transition();
  &:hover {
    color: $color-text;
  }
}
.c-nav-main{
  display: block;
  margin-bottom: 20px;
  li{
    .bg-menu{
      display: inline-block;
      background: url('../assets/images/menu1.png') no-repeat;
      width: 192px;
      height: 192px;
      vertical-align: middle;
      @include box-shadow(3px, 3px, 10px, rgba(0, 0, 0, 0.2));
    }
    .link-image{
      width: auto;
      margin: 0;
      padding: 0;
    }
    a{
      font-family: 'AvenirNextLTW01-UltraLi';
      font-weight: 200;
      font-size: 38px;
      line-height: 1.105;
      display: inline-block;
      width: 245px;
      padding: 0 0 25px 25px;
      vertical-align: bottom;
      @include transition();
      text-decoration: none;

      &:hover{
        font-family: 'AvenirNextLTW01-Regular';
        font-weight: 400;
      }
    }
      &.current-menu-item a{
        font-family: 'AvenirNextLTW01-Regular';
        font-weight: 400;
      }
    }
}

.impressum-link, .home-link{
  color: $color-white;
  display: inline-block;
  padding: 16px 1.459vw;
  text-decoration: none;
}
.impressum-link{
  float: right;

}
.home-link{
  float: left;
}

.submenu{
  li{
    a{
      color: $color-head;
      line-height: 1.357;
      font-size: 42px;
      @include transition;
      font-family: 'AvenirNextLTW01-UltraLi';
      font-weight: 200;
      text-decoration: none;

      &.active, &:hover{
        font-family: 'Avenir Next LT W01 Demi';
        font-weight: 600;
      }
    }
  }
}
.submenu-mobile{
  display: none;
  padding: 100px 22px 0;
  li{
    a{
        font-size: 24px;
        line-height: 1.25em;
        font-family: 'AvenirNextLTW01-UltraLi';
        color: $color-white;
        text-decoration: none;
    }
  }
}
.navbar_btn{
  display: none;
  position: absolute;
  top: 25px;
  right: 20px;
  width: 35px;
  height: 30px;
  z-index: 10;
  &[aria-expanded="false"] {
    background: url('../assets/images/close.svg') no-repeat;
  }
  &[aria-expanded="true"] {
    background: url('../assets/images/navbar_btn.svg') no-repeat;
    top: 25px;
    right: 20px;
    width: 34px;
    height: 30px;
  }
}


