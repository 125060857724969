// Footer
.footer{
  width: 100%;
  position: fixed;
  bottom: 0;
  background: #f2f2f2;
  padding: 0 0 12px;
  z-index: 10;
  .page-container{
    padding:  11px 1.459vw 0;
  }
  a{
    font-size: 15px;
    font-family: 'AvenirNextLTW01-Regular';
    color: #95989A;
  }
}

