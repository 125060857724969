// Headings

h1, h2, h3 {
  color: $color-head;
  margin: 0;
  word-break: break-word;
}
h1, h2{
  font-size: 16px;
  font-weight: 600;
  font-family: 'Avenir Next LT W01 Demi';
}
h3, h4{
  font-size: 15px;
  font-weight: 400;
  font-style: italic;
  font-family: 'AvenirNextLTW01-Regular';
  line-height: 1.4666666666666666;
}
p{
  margin-bottom: 0;
  font-weight: 600;
}

.home{
  h1 {
    font-size: 30px;
    line-height: 1.366;
    font-weight: 600;
  }
  h2 {
    font-size: 30px;
    line-height: 1.366;
    font-weight: 600;
  }
  p {
    font-size: 18px;
    line-height: 1.388;
    font-weight: 600;
  }
}
