// Colors
$color-white: #fff;
$color-black: #000;
$color-light-grey: #8b8a7c;
$color-brown: #572a2a;
$color-head: #8c3524;

// Text
$color-bg: $color-brown;
$color-text: #8b8a7c;

// Links
$color-link: $color-text;

// Borders
$color-border: $color-light-grey;
