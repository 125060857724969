// Settings global

// Font definitions
$font-georgia: 'Georgia', serif;
$font-times: 'Times', serif;
$font-serif: $font-times;

// Breakpoints
$bp-small: 320px;
$bp-medium: 768px;
$bp-large: 1024px;
$bp-full: 1600px;
